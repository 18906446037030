import React from "react";
import Layout from "../../components/layout/layout";
import {
  Base404,
  Logo,
  Headline,
  Subheadline,
  Link,
  Links,
} from "./_404.styled";
import { track } from "../../utils/analytics";
import { origins } from "../../utils/environment-urls";

export default () => {
  const seo = {
    robots: "noindex",
  };

  track("Page not found");

  return (
    <Layout seo={seo} isGuttered={true}>
      <Base404>
        <Logo src={require("../../images/broken-xom-logo.png")} />
        <Headline>Sorry! Looks like a part of this link is broken!</Headline>
        <Subheadline>
          While we order a replacement, maybe these pages can help:
        </Subheadline>
        <Links>
          <Link href="/">Home</Link>
          <Link href={`${origins.work}/job-board`}>Job Board</Link>
          <Link href={`${origins.work}/current-jobs`}>Current Jobs</Link>
          <Link href="/supplies/">Supplies</Link>
          <Link href={`${origins.get}/quoting/home`}>My Account</Link>
        </Links>
      </Base404>
    </Layout>
  );
};
