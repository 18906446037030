import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const Base404 = styled.div`
  text-align: center;
  color: #253842;
  max-width: 850px;
  margin: 0 auto;
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 418px;

  ${theme.smallerThan.medium} {
    width: 75%;
    height: 75%;
  }
`;

export const Headline = styled.h1`
  font-size: 32px;
  line-height: 44px;
  padding: 0 8%;
  color: #253842;

  ${theme.smallerThan.medium} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const Subheadline = styled.h2`
  font-size: 20px;
  line-height: 30px;
  padding: 0 8%;

  ${theme.smallerThan.medium} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 56px;

  ${theme.smallerThan.medium} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

export const Link = styled.a`
  flex-grow: 1;
  font-size: 24px;
  line-height: 34px;
  color: #1979c3;
  text-decoration: none;

  ${theme.smallerThan.medium} {
    font-size: 20px;
    line-height: 36px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
